$primary: #000000;
$secundary: #F5F4F2;
$tertiary: #B9D3D7;
$quaternary: #A5AAAE;
$quinary: #FBB4A6;
$alert: #FF0000;
$gray-1: #525252;

// html {
//     height  : 100%;
//     overflow: hidden;
//     position: relative;
// }
// body {
//     height  : 100%;
//     overflow: auto;
//     position: relative;
// }

.primary-color {
    color: $primary;
}

.secundary-color {
    color: $secundary;
}

.tertiary-color {
    color: $tertiary;
}

.quaternary-color {
    color: $quaternary;
}

.quinary-color {
    color: $quinary;
}

.alert-color {
    color: $alert;
}

.white-color {
    color: white;
}

.gray-1-color {
    color: $gray-1;
}

// background
.primary-bg-color {
    background-color: $primary;
}

.secundary-bg-color {
    background-color: $secundary;
}

.tertiary-bg-color {
    background-color: $tertiary;
}

.quaternary-bg-color {
    background-color: $quaternary;
}

.quinary-bg-color {
    background-color: $quinary;
}

.alert-bg-color {
    background-color: $alert;
}

.gray-1-bg-color {
    background-color: $gray-1;
}

.transparent-bg-color {
    span {
        background-color: transparent !important;
    }
}
.primary-border-color {
    border-color: $primary !important;
}

.secundary-border-color {
    border-color: $secundary !important;
}

.tertiary-border-color {
    border-color: $tertiary !important;
}

.quaternary-border-color {
    border-color: $quaternary !important;
}

.quinary-border-color {
    border-color: $quinary !important;
}

.alert-border-color {
    border: 1px solid $alert !important;
}

.bg-gradient-1 {
    background: rgb(235, 208, 180);
    background: -moz-linear-gradient(180deg, rgba(235, 208, 180, 1) 0%, rgba(251, 180, 166, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(235, 208, 180, 1) 0%, rgba(251, 180, 166, 1) 100%);
    background: linear-gradient(180deg, rgba(235, 208, 180, 1) 0%, rgba(251, 180, 166, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebd0b4", endColorstr="#fbb4a6", GradientType=1);
}

@mixin toAnim ($tempo) {
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

// fonts

@font-face {
    font-family: 'Gotham Book';
    src: url('../assets/fonts/Gotham-Book.eot');
    src: url('../assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('../assets/fonts/Gotham-Book.woff') format('woff'),
        url('../assets/fonts/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-Black.eot');
    src: url('../assets/fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-Black.woff2') format('woff2'),
        url('../assets/fonts/Gotham-Black.woff') format('woff'),
        url('../assets/fonts/Gotham-Black.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-ThinItalic.eot');
    src: url('../assets/fonts/Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-ThinItalic.woff2') format('woff2'),
        url('../assets/fonts/Gotham-ThinItalic.woff') format('woff'),
        url('../assets/fonts/Gotham-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-Light.eot');
    src: url('../assets/fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-Light.woff2') format('woff2'),
        url('../assets/fonts/Gotham-Light.woff') format('woff'),
        url('../assets/fonts/Gotham-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-BookItalic.eot');
    src: url('../assets/fonts/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../assets/fonts/Gotham-BookItalic.woff') format('woff'),
        url('../assets/fonts/Gotham-BookItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-Thin.eot');
    src: url('../assets/fonts/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-Thin.woff2') format('woff2'),
        url('../assets/fonts/Gotham-Thin.woff') format('woff'),
        url('../assets/fonts/Gotham-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-Bold.eot');
    src: url('../assets/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-Bold.woff2') format('woff2'),
        url('../assets/fonts/Gotham-Bold.woff') format('woff'),
        url('../assets/fonts/Gotham-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-XLight.eot');
    src: url('../assets/fonts/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-XLight.woff2') format('woff2'),
        url('../assets/fonts/Gotham-XLight.woff') format('woff'),
        url('../assets/fonts/Gotham-XLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-UltraItalic.eot');
    src: url('../assets/fonts/Gotham-UltraItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-UltraItalic.woff2') format('woff2'),
        url('../assets/fonts/Gotham-UltraItalic.woff') format('woff'),
        url('../assets/fonts/Gotham-UltraItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../assets/fonts/Gotham-XLightItalic.eot');
    src: url('../assets/fonts/Gotham-XLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-XLightItalic.woff2') format('woff2'),
        url('../assets/fonts/Gotham-XLightItalic.woff') format('woff'),
        url('../assets/fonts/Gotham-XLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Stretch Pro';
    src: url('../assets/fonts/StretchProRegular.eot');
    src: url('../assets/fonts/StretchProRegular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/StretchProRegular.woff2') format('woff2'),
        url('../assets/fonts/StretchProRegular.woff') format('woff'),
        url('../assets/fonts/StretchProRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//style

body {
    font-size: 16px;
    font-family: 'Gotham Book' !important;
}

h2 {
    /* font-size: 30px; */
    font-size: 1.5rem;
    font-family: 'Gotham Book';
}

h3 {
    /* font-size: 18px; */
    font-size: 1.1rem;
    font-family: 'Gotham Book';
}

h4 {
    /* font-size: 18px; */
    font-size: 1rem;
    font-family: 'Gotham Book';
}

p {
    /* font-size: 16px; */
    font-family: 'Gotham Book';
    font-size: 0.9rem;

    &.big-p {
        font-size: 1.3rem;
    }
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

.gotham {
    font-family: 'Gotham Book';
}

.gotham-black {
    font-family: 'Gotham';
    font-weight: 500;
}

.gotham-ThinItalic {
    font-family: 'Gotham';
    font-weight: 100;
    font-style: italic;
}

.gotham-Light {
    font-family: 'Gotham';
    font-weight: 300;
}

.gotham-BookItalic {
    font-family: 'Gotham';
    font-weight: 500;
    font-style: italic;
}

.gotham-Thin {
    font-family: 'Gotham';
    font-weight: 100;
}

.gotham-Bold {
    font-family: 'Gotham';
    font-weight: bold;
}

.gotham-XLight {
    font-family: 'Gotham';
    font-weight: 300;
}

.gotham-UltraItalic {
    font-family: 'Gotham';
    font-weight: 500;
    font-style: italic;
}

.stretch-pro {
    font-family: 'Stretch Pro';
}


.btn-g,
a.btn-g,
button.btn-g {
    text-decoration: none;
    display: inline-block;
    color: $primary !important;
    background-color: white;
    border: 1px solid #dee2e6;
    font-family: 'Gotham Book';
    font-size: 0.8rem;
    text-transform: uppercase !important;
    @include toAnim(0.3s);

    &:hover {
        background-color: $primary;
        color: white !important;
    }

    &.bb {
        background-color: black;
        color: white !important;

        &:hover {
            background-color: white;
            color: black !important;
        }
    }
    &.active{
        background-color: $primary;
        color: white !important;
    }
}

.btn-big {
    background-color: rgba(white, 0.7);
    text-decoration: none;
    text-transform: uppercase !important;
    &.bb {
        background-color: black;
        color: white;
        &:hover {
            background-color: rgba(white, 0.7);
            color: black;
        }
    }
}



.btn-big {
    font-family: 'Gotham Book';
    color: black;
    transform: translateX(-50%);
    border: 1px solid black;
    @include toAnim(0.5s);

    &:hover {
        background-color: black;
        color: white;
    }
}

.note-publi {
    background-color: #F5F4F2;
    font-family: 'Gotham Book';

    a {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}

.banner {
    overflow: hidden;
    .btn-big {
        position: absolute;
        bottom: 3rem;
        left: 50%;
        z-index: 1;
        border: 0;
        background-color: transparent;
        color: #000;
        border: 1px solid #000;
    }

    .content-banner {
        width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .c-special{
        width: 6.3%;
        position: absolute;
        transform-origin: top right;
        right:0;
        top:30%;
        transform: rotate(-90deg) translate(-50%, -100%);
    }

    .special-link{
        background-color: #F5F4F2;
        color: black;
        text-decoration: none;
        border: 0;
        font-size: 0.8rem;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        text-transform: uppercase;
        @include toAnim(0.5s);
        &:hover{
            background-color: black;
            color: white;
        }
    }

}

.algo-para-contar {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #F5F4F2;
    padding: 1rem;
    @include toAnim(0.3s);

    img {
        width: 0.7vw;
        @include toAnim(0.3s);
    }

    &:hover {
        padding: 1.2rem;
    }
}

.special-item {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba($primary, 0.4);
        opacity: 0;
        @include toAnim(0.5s)
    }

    .txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        h2 {
            text-align: center;
            color: white;
            text-transform: uppercase;
        }
    }

    &-hover:hover {
        &::before {
            opacity: 1;
        }
    }
    &-hover-text:hover{
        .txt {
            h2 {
                text-decoration: underline;
            }
        }
    }
}


form {

    accent-color: #8d8d8d;

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='tel'],
    input[type='password'],
    input[type='date'],
    input[type='time'],
    select,
    label,
    textarea {
        width: 100%;
        font-size: 1.1rem;
        font-family: 'Gotham Book';
    }

    input,
    select,
    textarea {
        border: 1px solid #dee2e6;
    }

    input,
    textarea {
        &:focus {
            outline: none !important;
            /* border: 0px solid black !important; */
            box-shadow: 0 0 0px $primary !important;
            /* box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%) !important; */
        }
    }

    textarea {
        min-height: 10rem;
    }

    label {
        text-transform: uppercase;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    select,
    option{
        -webkit-border-radius:0px;
        -webkit-appearance: none;
    }
    
    input{
        -webkit-border-radius:0px;
    }

    input[type='date'], input[type='time'] {
        -webkit-appearance: none;
        background-color: white;
    }
    .talla {
        position: relative;
        display: inline-block;

        label {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        input[type=radio].css-checkbox {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            top: 0;
            left: 0;
            /* margin:-1px;  */
            padding: 0;
            border: 0;
        }

        input[type=radio].css-checkbox+label.css-label {
            /* padding-left:20px; */
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #dee2e6;
            font-size: 1rem;
            vertical-align: middle;
            cursor: pointer;
        }

        input[type=radio].css-checkbox:checked+label.css-label {
            background-color: black;
            color: white;
        }
    }

    .precio {
        position: relative;
        display: inline-block;

        label {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        input[type=radio].css-checkbox {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            top: 0;
            left: 0;
            /* margin:-1px;  */
            padding: 0;
            border: 0;
        }

        input[type=radio].css-checkbox+label.css-label {
            /* padding-left:20px; */
            width: auto;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #dee2e6;
            font-size: .85rem;
            vertical-align: middle;
            cursor: pointer;
        }

        input[type=radio].css-checkbox:checked+label.css-label {
            background-color: black;
            color: white;
        }
    }

    .color-radio {
        display: inline-block;
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }

        input:checked+.check {
            /* background-color: red; */
            border: 2px solid #8d8d8d;
        }

        .check {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: 0px solid #B9D3D7;
        }
        
    }
    .border-w {
        input:checked + .check { border: 2px solid rgb(136, 136, 136) !important; }
    }

    .counter {
        font-size: 1rem;

        div {
            div {
                padding: 0.2rem !important;
            }
        }
    }

    /* The container */
    .containerCheck {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .containerCheck input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .containerCheck {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .containerCheck:hover input~.checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .containerCheck input:checked~.checkmark {
        background-color: gray;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .containerCheck:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .containerCheck input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .containerCheck .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .link-reset-password {
        font-size: 0.8rem;
    }

    //check gris pequeño
    .container-check-s {
        display: block;
        display: flex;
        align-items: center;
        /* height: 15px; */
        height: auto;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 0.8rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        small {
            color: white;
            font-size: 0.8rem;
        }

        p {
            /* text-transform: capitalize; */
            text-transform: initial;
        }
    }

    /* Hide the browser's default checkbox */
    .container-check-s input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .container-check-s .checkmark-s {
        position: absolute;
        top: 46%;
        transform: translateY(-46%);
        left: 0;
        height: 13.5px;
        width: 13.5px;
        background-color: #eee;
        display: flex;
        justify-content: center;
        align-items: center;

        &.border {
            border: 1px solid black !important;
        }
    }

    /* On mouse-over, add a grey background color */
    .container-check-s:hover input~.checkmark-s {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container-check-s input:checked~.checkmark-s {
        /* background-color: #2196F3; */
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark-s:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container-check-s input:checked~.checkmark-s img {
        visibility: visible;
        opacity: 1;
        height: 20px;
    }

    /* Style the checkmark/indicator */

    .container-check-s img {
        height: 0px;
        visibility: hidden;
        opacity: 1;
        @include toAnim(0.3s);
    }

    /*  .container-check-s .checkmark-s:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  } */

}

.bolsa-checkout {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}



.item-collapse {
    a {
        text-decoration: none;

        span {
            color: $primary;
            @include toAnim(0.3s);
        }

        &:hover {
            span {
                color: $gray-1;
            }

        }
    }
}

.NavPoliticas {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        a {
            color: $primary;
            text-decoration: none;
            font-family: 'Gotham Book';
            @include toAnim(0.3s);

            &:hover {
                color: $gray-1;
            }
        }
    }
}

.swiper {

    .swiper-button-next,
    .swiper-button-prev {
        width: 2rem;
        height: 2rem;
        // background-color: $secundary;
        background-size: 60% auto;
        background-position: center;
        background-repeat: no-repeat;
        // border: 1px solid black;
        margin-top: 0;
        transform: translateY(-50%);
        @include toAnim(0.3s);

        &:hover {
            background-color: $quaternary;
        }

        &::after {
            font-size: 0;
            color: transparent;
        }
    }

    .swiper-button-next {
        background-image: url('../public/img/SVG/carbon_arrow-right.svg');
        right: 0;
    }

    .swiper-button-prev {
        background-image: url('../public/img/SVG/carbon_arrow-left.svg');
        left: 0;
    }

    .btn-swiper-navigation {
        &:hover{
            background-color: transparent;
            border: 0;
            color: $primary;
            box-shadow: none !important;
        }

        &.review-swiper-button-prev {
            top: 35%;
            position: absolute;
            left: 0;
            z-index: 9;
        }
        &.review-swiper-button-next {
            top: 35%;
            position: absolute;
            right: 0;
            z-index: 9;
        }

        &:disabled {
            display: none;
        }
    }
}

.product {
    .swiper {

        .swiper-button-next,
        .swiper-button-prev {
            width: 1rem;
            height: 1rem;
        }
    }
}

.product-filter {
    @include toAnim(0.5s);
}

.modal {
    .close-modal {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.5rem;
        z-index: 10;
    }
}

.img-blur {
    cursor: pointer;

    img {
        @include toAnim(0.5s);
    }

    &:hover {
        img {
            filter: blur(4px);
            -webkit-filter: blur(4px);
        }
    }
}

.head-feed-intagram {
    a {
        color: black;
        text-decoration: none;
    }
}

.lightwidget-widget {
    width: 100%;
}

.mapUbicacion {
    width: 100%;
    height: 400px;

    &>div {
        width: 100%;
        height: 100%;
    }
}


.content-user {
    background-position: center;
    background-size: cover;
}

.register-pager {
    background-color: #fefefe;
}

.user {
    // background: linear-gradient(180deg, #EBD0B4 0%, #FBB4A6 100%);
    background-color: #F5F4F2;
    .simple-link,
    a {
        text-decoration: none;
        color: black;
    }

    h2 {
        font-size: 1rem !important;
    }

    .container-check-s {
        .checkmark-s {
            border: 1px solid black !important;
        }

        small {
            color: black;
        }
    }

}

//Add by Oscar please reorder
@import url('./lib/animate.min.css');

.error-msg {
    text-align: center;
    background: white;
    margin-bottom: 13pt;
    color: #8d8d8d;
    font-size: .75rem;
    font-style: italic;
}

.select-cart-fast {
    border: 1px solid #ced4da;
    width: 100%;
    border-radius: 0.375rem;
    min-height: 25px;
    font-size: .75rem;
}

.delete-product {
    position: absolute;
    top: 0;
    right: 17pt;
    font-size: 1.5rem;
    color: $primary;
    cursor: pointer;
    z-index: 10;
    @include toAnim(0.3s);
    &:hover {
        color: $gray-1;
    }
}

.abs-info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    text-align: center;
    width: 100%;
    padding: 3pt;
    background: #ffffff61;
    font-size: 10pt;
}

.succesfull-msg {
    padding: 10pt;
    text-align: center;
    background: white;
    margin-bottom: 13pt;
    color: green;
}

.text-center-c {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.loader-box {
    margin: 10vh 0;
    text-align: center;

    img {
        width: 100pt;
    }
}

.chip {
    position: relative;
    background: #dbdada;
    padding: 4pt;
    border-radius: 10pt;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2pt;

    a {
        display: contents;
        color: inherit;
        text-decoration: none;
    }
}

header {
    .options {
        ul {
            li {
                a {
                    position: relative;
                    .name-user{
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 0.7rem;
                        margin: 0;
                    }
                    .badge {
                        position: absolute;
                        /* background: #22913a; */
                        background-color: black;
                        font-size: 7pt;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

.animationIconCart {
    animation: animationIconCart 1s infinite;
}

.upper {
    text-transform: uppercase;
}

.disabled {
    pointer-events: none;
    filter: grayscale(100%);
    background: #e0e0e0;
    opacity: 0.3;
}
.buttons-methods {
    .btn-method {
        background: #000000;
        color: white;
        padding: 5pt 10pt;
        margin: 5pt 0;
        width: 225pt;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 5pt;
        font-size: .75rem;
        &:hover {
            background: #3a3a3a;
            color: white;
        }
    }
}
.banner-contacto {
    padding-top: 4rem;
    padding-bottom: 7rem;
}
.ficha-arjeta-hover {
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 5pt #000000a6;
    }
}
.ficha-arjeta {
    .error-msg {
        position: absolute;
        top: 8px;
        right: 30px;
    }
}
.icon-ok {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #5b8d76;
}
.icon-not-selected {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #acacac;
}
.select-only-read {
    appearance: none;
    border: 0;
    color: black;
}

.button-input-float {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

// .product-page {
//     padding-top: 10rem;
// } 

.container-load-search {
    width: 80%;
    padding-right: 0.5rem;
}

.image-product-interna {
    position: relative;
    overflow: hidden;
    width: 100%;

    .image-principal {
        // opacity: 1;
        width: 100%;
        // // pointer-events: none;
        // transition: opacity .25s ease-in;
    }

    .selector {
        display: none;
        position: absolute;
        width: 15vw;
        height: 10vw;
        // background-color: rgba(82, 82, 82, 0.5);
        pointer-events: none;
    }

    .zoom-element {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right:0;
        background-repeat: no-repeat;
        z-index: 5;
        transition: opacity .25s ease-in;
    }
    
    .zoom-box {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0%;
        overflow: hidden;
        pointer-events: none;
        img {
            position: absolute;
            width: 150%;
        }
        transition: opacity .25s ease-in;
    }

    &:hover {
        // .selector {
        //     display: block;
        // }
        // .image-principal {
        //     opacity: 0;
        // }
        
        .zoom-box {
            opacity: 1;
        }
    }
}

@keyframes animationIconCart {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.main {
    &.no-padding {
        padding: 0;
    }
}

.gif-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: fixed;
    background: #000000d2;
    z-index: 100000;
    img {
        width: 100pt;
    }
}

.btn-show-pass {
    right: 2.5%;
    position: absolute;
    font-size: 1.15rem !important;
    height: 35px;
    display: flex;
    top: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #8d8d8d !important;
}

.item-ayuda-contacto {
    .wp {
        p {
            margin-bottom: 0;
        }
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

.text-especial {
    font-size: .75rem;
}

input, select {
    min-height: 35px;
}

.modal-new-card {
    .modal-dialog {
        // @extend .modal-dialog-centered;
        align-items: center;
        display: flex;
        min-height: calc(100% - var(--bs-modal-margin)*2);
        width: 40%;
        .modal-content {
            background-color: $secundary;
        }
    }
}

.titulo-nfts {
    h2 {
        font-family: "Stretch Pro";
        font-weight: 700;
        text-transform: uppercase;
    }
}

.moda-consciente {
    a {
        color: $primary;
        &:hover{
            text-decoration: underline !important;
            color: $primary;
        }
    }
}

.inputdate::-webkit-calendar-picker-indicator {
    display: none;
}

.inputdate[type="date"]::-webkit-input-placeholder {
    visibility: hidden !important;
}

.modal{
    .modal-dialog{
        max-width: 100%;
        margin-top: 0;
        .modal-content{
            border-radius: 0;
        }
    }
}

/* .modal-backdrop{
    background-color:white !important;
    opacity: 1 !important;
} */
.modal-content{
    border: 0 !important;
}
/* .modal {
    .modal-dialog{
        max-width: 100%; 
        .modal-content{
            width: 100vw;
            height: 100vh;
        }
    }
} */

.wp-idiomas-mobile {
    left: 85%;
    position: absolute !important;
    transform: translateY(50%);
}

.button-close {
    font-size: .5rem;
    font-weight: 600;
    font-family: "Gotham";
    color: $primary;
    text-decoration: none;
}

.box-checkout-invitado {
    height: 270px;
}

.cursor-pointer {
    cursor: pointer;
}

.over-hidden {
    overflow: hidden;
}

.px-05 {
    padding-right: .125rem !important;
    padding-left: .125rem !important;
}
.float-brand {
    position: absolute;
    top: 18pt;
    right: 0;
    width: 30pt;
    z-index: 1;
    img {
        width: 100%;
    }
}

.modal-imagen-min {
    max-height: 100vh;
    .modal-dialog {
        height: 100%;
        .modal-content {
            background-color: transparent;
            img {
                max-height: 100vh;
                display: block;
                margin: 0 auto;
            }
            .close-modal span{
                color: white;
                font-size: .65rem;
            }
            .review-swiper-button-prev, .review-swiper-button-next {
                top: 50%;
                font-size: 1rem;
                outline: none;
            }
        }
    }
}

.new-address-checkout {
    .mapUbicacion {
        width: 100%;
        height: 350px;
    }
}

.head-perfil {
    background-color: #dee2e6;
}

.cursor-none {
    cursor: auto;
}

.icon-gray {
    color: #8d8d8d;
}

.tooltip {
    .tooltip-inner {
        background-color: #8d8d8d;
        width: 75%;
        margin: auto;
        height: 60px;
        margin-left: 4rem;
        padding-top: .5rem;
    }
    .tooltip-arrow {
        border-color: #8d8d8d;  
    }
    small {
        font-size: 0.825rem;
        margin: 0;
        line-height: 2px;
    }
}

.text-gray {
    color: #8d8d8d !important;
}

.text-grey {
    color: #8f8f8f!important;
}
.text-transform-none {
    text-transform: none;
}
.favorito-icon {
    font-size: 1rem;
}
.fw-bold {
    font-weight: 700;
    font-family: 'Gotham';
}

.react-select__control {
    height: 30px;
    .react-select__value-container {
        height: 30px;
        padding: 1px 6px;
        .react-select__placeholder, .react-select__input-container {
            height: 30px;
            font-size: .85rem;
            margin: auto 2px;
            display: flex;
            justify-content: start;
            align-items: center;
        }
    }
}

.react-select__menu {
    max-height: 100rem;
    top: 0;
    overflow: hidden;
}
.categorias-slider {
    .btn-swiper-navigation {
        top: 45% !important;
    }
}

.cvv-label {
    label{
        width: 5.5%;
    }

    .icon{
        color: #8d8d8d;
    }
}
.rounded-full {
    border-radius: 100%;
}

#table {
    height: 270px;
}

.item-nueva-coleccion {
    position: relative;
    
    .btns-item-eva-coleccion {
        position: absolute;
        width: calc(100% - 2rem);
        bottom: 2rem;
        left: 2rem;
    }
    img {
        padding: 0 !important;
    }
}

.ps-0125{ padding: 0 0 0 0.125rem !important; }
.pe-0125{ padding: 0 0.125rem 0 0 !important; }

.hover-underline {
    &:hover{
        text-decoration: underline !important;
        color: #000000;
    }
}

.screen_h_100 {
    height: 31rem;
}

.color_imp_primary {
    h2 {
        color: $primary !important;
    }
}

.estrategia-page {
    h2 {
        font-size: "Gothma Book";
        font-weight: 500;
    }
}

.titulo-banner-collecion {
    top: 15%;
    left: 10%;
    h2 {
        font-size: 1.5rem !important;
        font-style: italic;
    }
}
#header {
    .panel-header {
        min-height: 70px;
    }
}

.line-delgada {
    height: .25rem;
}

.feedback-back{
    background-color: #F5F4F2;
}

.favicon-banner {
    width: 30px;
    position: absolute;
    bottom: 18%;
    left: calc(50% - 12px);
}

.shadow-giftcard {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25);
}

input::-webkit-date-and-time-value{ 
    text-align:left; 
    background-color: white;
    height: 100%;
    color: #000;
}

.banner-ready-to-wear {
    .btn-big{
        position: relative;
    }
}

strong {
    font-family: 'Gotham' !important;
    font-weight: 800 !important;
}

.content-paypal {
    position: relative;
    z-index: 1;
}


.content-cookies {
    width: 100%;
    height: 150px;
    position: fixed;
    top: 100%;
    z-index: 998;
    background-color: white;
    transform: translateY(-150px);
    p {
        margin: 0;
        text-align: center;
    }
}

.label-talla-disabled {
    background-color: #bcbcbc;
}

.banner-women img {
    filter: grayscale(75%) brightness(0.8);
}

.placeholder-gris {
    &::placeholder { 
        text-transform: uppercase;
        color: #A5AAAE; 
    }
}