
.banner-women{
    .container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h2{
            color: white;
            font-family: 'Stretch Pro';
            font-size: 2rem !important;
            letter-spacing: 1px;
        }
        p{
            color: white;
            font-size: 1.5rem;
        }
    }
}


@media (max-width: 576px) {
  
    .banner-women{
        .container{
            left: inherit;
            padding: 1rem;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(0);
            transform: translate(0, -50%);
            h2{
                color: black;
                font-weight: 700 !important;
                font-family: 'Gotham';
            }
            p{
                color: white;
            }
        }
    }

}

@media (min-width: 768px) {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    
}

@media only screen and (min-width: 1200px){
    
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
 
    .banner-women{
        .container{
            h2{
                font-size: 2vw !important;
            }
            p{
                
                font-size: 1.5vw;
            }
        }
    }
    
}