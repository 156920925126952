@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.ficha-pago-exitoso{
    width: 80%;
    margin: 0 auto;
}


@media (max-width: 576px) {
  
}

@media (min-width: 768px) {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    
}

@media only screen and (min-width: 1200px){
    
    .ficha-direccion{
        width: 100%;
    }

    .ficha-pago-exitoso{
        width: 45%;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
 
  

}