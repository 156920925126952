@media (min-width: 768px) {
    h2{
        font-size: 1.5rem !important;
    }
    h3{
        font-size: 1.1rem !important;
    }
    h4{
        font-size: 1.1rem !important;
    }
    p{
        font-size: 0.9rem;
        &.big-p{
            font-size: 1.2rem;
        }
    }
    
    .btn-g{
        font-size: 0.9rem;
    }

    .icono-metodo{
        img{
          height: 1.8rem;
          &.visa{
            height: 1.5rem;
          }
        }
    }

    .modal{
        /* .modal-dialog{
            max-width: 760px;
            &.modal-xl{
                width: 90%;
                max-width: 1560px;
            }
        } */
        
        .modal-header{
            border: 0;
        }
    }
    
    .lightwidget-widget{
        height: 12rem;
        margin-bottom: -10px;
    }

    form{
        .talla{
            position: relative;
            display: inline-block;
            
            input[type=radio].css-checkbox {
                height:1px; 
                width:1px; 
            }
            
            input[type=radio].css-checkbox + label.css-label {
                width: 1.5rem;
                height:1.5rem;
                font-size: 0.8rem;
            }
        }

        .color-radio{
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    .item-ayuda-contacto{
        .content{
            min-height: 6rem;
        }
    }

}

@media (min-width: 768px) and (max-width: 1200px) {
    .feedback{
        background-color: #B9D3D7;
        .fondo{
            display: none;
        }
        .content-banner{
            width: 100%;
            position: relative;
            top: inherit;
            left: inherit;
            transform: translate(0%, 0%);
            padding: 2rem;
        }
    }
}