footer{
    h4{
        text-transform: uppercase;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            a, button{
                text-decoration: none;
                text-transform: uppercase;
                display: inline-block;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                font-family: 'Gotham Book';
                &:hover{
                    color: #A5AAAE;
                }
            }
        }
        button{
            text-align: left;
            background-color: transparent;
            border: 0;
            padding: 0;
        }
    }
    form{
        input[type="email"]{
            background-color: transparent;
            color: white;
            border-color: white;
        }
        button{
            border-color: white !important;
            text-transform: uppercase;
            font-family: 'Gotham Book' !important;
            font-weight: bold !important;
        }
        .note{
            font-family: 'Gotham Book';
            font-weight: 300;
            font-style: italic;
            color: white;
        }
    }
    .social{
        a{
            font-size: 2rem;
            svg{
                path{
                    fill: white;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover{
                svg{
                    path{
                        fill: #A5AAAE;
                    }
                }
            }
        }
    }
    .franquicias{
        a, p{
            display: inline-block;
            img{
                height: 2rem;
            }
        }
    }
}

@media (max-width: 576px) {
    footer{
        ul{
            li{
                a, button{
                    font-size: 0.8rem;
                }
            }
        }
        form{
            input[type="email"]{
                width: 80%;
            }
            .btn-g{
                font-size: 0.8rem;
            }
            .container-check-s {
                small{
                    font-size: 0.6rem;
                }
            }
        }

        .social {
            a{
                font-size: 1.2rem;
            }
        }

        .iridian{
            width: 3rem;
        }

        .franquicias {
            a,p {
                img{
                    height: 1.5rem;
                }
            }
        }

    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    footer{
        .container{
            max-width: 90%;
        }
        p{
            text-transform: uppercase;
            font-size: 0.7vw;
        }
        form{
            label{
                small{
                    font-size: 0.5vw;
                    line-height: 100%;
                }
            }   
            button{
                font-size: 0.8vw !important;
            }
            .note{
                font-size: 0.8vw !important;
            }
        }
        h4{
            font-size: 0.9vw !important;
        }
        ul{
            li{
                a, button{
                    font-size: 0.8vw;
                }
            }
        }
        .logo-footer{
            width: 16vw;
            width: 100%;
        }
        .social{
            a{
                font-size: 1.2vw;
                color: #B9D3D7;
                svg{
                    path{
                        fill: #B9D3D7;
                    }
                }
            }
        }
        .iridian{
            width: 5vw;
        }
        .franquicias{
            a,p{
                img{
                    height: 2vw;
                }
            }
        }
    }
}