@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.item-filtro-link{
    border: 1px solid #dee2e6;    
    color: black;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.7rem 1.4rem;
    &:hover{
        color: black;
    }
}
.item-filtro{
    border: 1px solid #dee2e6;    
    color: black;
    text-decoration: none;
    .dropdown-toggle{
        text-decoration: none;
        color: black;
        padding: 0.7rem 1.4rem;
        display: inline-block;
        &::after{
            display: none;
        }
        span, svg{
            display: inline-block;
            vertical-align: middle;
        }
        span{
            margin-right: 0.5rem;
        }
    }
    .dropdown-menu{
        padding: 0.5rem;
        a{
            display: block;
            text-decoration: none;
            color: black;
        }
        li{
            label{
                a{
                    color: black;
                    text-decoration: none;
                    font-size: 0.9rem;
                    white-space: nowrap;
                    margin-bottom: 12px;
                }
                small{
                    color: black;
                }
            }
            .container-check-s{
                padding-left: 20px;
                small{
                    color: black;
                    font-size: 0.9rem;
                    white-space: nowrap;
                }
                .checkmark-s{
                    background-color: white;
                    border: 1px solid black;
                }
            }
        }
    }
}



@media (max-width: 576px) {
    .product-filter{
        width: 60%;
        padding: 1rem !important;
    }
}

@media (max-width: 1200px) {
    .product-filter{
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1001;
        padding: 2rem;
        visibility: hidden;
        opacity: 0;
        background-color: white;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
        &.view{
            top: 50%;
            visibility: visible;
            opacity: 1;
        }
    }
    .dropdown-toggle{
        width: 100%;
        span{
            width: 90%;
        }
    }
    .item-filtro-link{
        width: 100%;
        display: inline-block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px){
    .product-filter{
        width: 50%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {


}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    .product-filter{
        width: 30%;
        select{
            font-size: 0.9rem;
        }
    }

}

@media (min-width: 1200px) {
    .opcion-mobile{
        display: none;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    .product-filter{
        select{
            max-width: 8vw;
            font-size: 0.9vw;
        }
        .ordenar{
            max-width: none;
            padding: 0.7rem 1.4rem;
        }
    }
    .item-filtro-link{
        font-size: 0.8vw;
        padding: 0.5vw 0.7vw;
    }

    .item-filtro{
        .dropdown-toggle{
            /* font-size: 0.9vw;
            padding: 0.7rem 1.4rem; */
            font-size: 0.8vw;
            padding: 0.5vw 0.7vw;
        }
        .dropdown-menu{
            padding: 5px 10px;
            li{
                label{
                    span, a{
                        font-size: 0.8vw;
                    }
                }
                .container-check-s{
                    padding-left: 20px;
                    small{
                        color: black;
                        font-size: 0.8vw;
                        white-space: nowrap;
                    }
                    .checkmark-s{
                        background-color: white;
                        border: 1px solid black;
                    }
                }
            }
        }
    }

}

@media (min-width: 1920px) {
    .product-filter{
        select{
            max-width: 8rem;
            font-size: 0.9rem;
        }
    }
}