@media (min-width: 576px) {
    header{
      .logo{
        height: 2rem;
      }
    }

    .buttons-methods {
      .btn-method {
        width: 170pt;
      }
    }
}