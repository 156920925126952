@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.product{
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 0 .125rem;
    picture{
        display: block;
        a.Fav{
            z-index: 9;
            top: 1rem;
            right: 1rem;
            font-size: 1.5rem;
            display: flex;
            align-items: flex-start;
            
            svg{
                display: inline-block;
                height: auto;
                @include toAnim(0.3s);
            }
            &:hover{
                color: #525252;
            }
        }
    }
    h3{
        font-size: 1.1rem;
        text-transform: uppercase;
    }
    p{
        font-size: 1.1rem;
        span{
            text-decoration: line-through;
        }
    }

    .precio-item {
        transition: all 0.3s ease-in;
    }

    .options{
        width: 100%;
        // border: 1px solid black;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        background-color: white;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    &:hover{
        z-index: 2;
        .precio-item {
            opacity: 0;
        }
        .options{
            visibility: visible;
            opacity: 1;
        }
    }
}

.btn-favorite-product.favorite {
    background-color: black;
    color: white !important;
}

.home-product{
    .product{
        .slider{
            display: none;
        }
    }
}

.buttom-nuevo {
    padding: 0.25rem 0.5rem;
    font-size: .6rem !important;
}

@media (max-width: 576px) {
    .product{
        h3{
            font-size: 0.75rem !important;
        }
        p{
            font-size: 0.7rem !important;
        }
        .buttom-nuevo {
            font-size: 0.7rem !important;
            width: 45%;
            text-align: center;
            border: 1px solid #dee2e6;
            // background-color: #525252;
            text-transform: capitalize;
        }
    }
}

@media (min-width: 768px) {
    .product{
        h3{
            font-size: 0.9rem;
        }
        p{
            font-size: 0.8rem;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    .product{
        picture{
            a.Fav{
                font-size: 1.6vw;
                top: 1vw;
                right: 1vw;
            }
        }
        h3{
            font-size: 0.9vw;
        }
        p{
            font-size: 0.9vw;
        }
    }   
}