.product-page{

    .product-description{
        .price-before{
            text-decoration: line-through;
            color: #525252;
        }
        p{
            font-size: 0.9rem;
        }
        .btn-collapse{
            .ico{
                height: 1rem;
            }
        }
    }
}

figure.zoom {
    background-position: 50% 50%;
    position: relative;
    margin: 150px auto;
    border: 5px solid white;
    box-shadow: -1px 5px 15px black;
    height: 300px;
    width: 500px;
    overflow: hidden;
    cursor: zoom-in;
}
figure.zoom img:hover {
    opacity: 0;
}
figure.zoom img {
    transition: opacity 0.5s;
    display: block;
    width: 100%;
}


.content-cantidad{
    width: 10rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    .product-page{
        padding-top: 4vw;
        .container{
            max-width: 95%;
        }
        .images{
            width: 52%;
        }
        .product-description{
            width: 48%;
            padding-left: 2vw;
            h3{
                font-size: 1.25vw !important;
                &.precio {
                    color: #8f8f8f !important;
                    font-size: 1.2vw !important;
                }
            }
            .find-my-size{
                font-size: 0.7vw;
                &:hover{
                    font-weight: 600;
                    color: black;
                }
            }
            p{
                font-size: 0.9vw;
            }
    
            .btn-add-car{
                font-size: 0.9vw;
            }
    
            .icon-add-car, .icon-fav-b{
                font-size: 1.5vw;
            }
        }
    }
}