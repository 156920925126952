.combinacion-page{
    width: 100%;
    .add{
        font-size: 1rem;
        font-family: 'Gotham';
        font-weight: 300;
        .icon{
            font-size: 1.4vw;
        }
    }
}

@media (max-width: 576px) {
    .combinacion-page{
        width: 100%;
        .stickybox{
            width: 100%;
            position: relative;
            z-index: 0;
        }
        .combinaciones{
            width: 100%;
            background-color: white;
            position: sticky;
            z-index: 2;
            h2{
                font-size: .7rem !important;
            }
            .combinacion-item {
                .abs-info {
                    font-size: .5rem !important;
                }

                h4 {
                    font-size: .7rem !important;
                }
            }
        }
        .add{
            font-size: .5rem;
            font-family: 'Gotham';
            font-weight: 300;
            .icon{
                font-size: .6rem;
            }
        }
    }
}

@media (min-width: 768px) {

    .combinacion-page{
        display: flex;
        align-items: flex-start;
        width: 100%;
        .stickybox{
            width: 44%;
        }
        .combinaciones{
            width: 56%;
        }
        .add{
            font-size: 1vw;
            font-family: 'Gotham';
            font-weight: 300;
            .icon{
                font-size: 1.4vw;
            }
        }
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    
}

@media only screen and (min-width: 1200px){
    

    .combinacion-page{
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding-top: 3vw;
        .stickybox{
            width: 44%;
        }
        .combinaciones{
            width: 56%;
        }
        .add{
            font-size: 1vw;
            font-family: 'Gotham';
            font-weight: 300;
            .icon{
                font-size: 1.4vw;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
 
}