@media (min-width: 1200px) {
    
    form{
        .talla{
            position: relative;
            display: inline-block;
            
            input[type=radio].css-checkbox {
                height:1px; 
                width:1px; 
            }
            
            input[type=radio].css-checkbox + label.css-label {
                width: 2rem;
                height: 2rem;
                font-size: 1rem;
            }
        }

        .color-radio{
            width: 2rem;
            height: 2rem;
        }
    }
}