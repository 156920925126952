.nav-perfil{
    list-style: none;
    li{
        a{
            text-decoration: none;
            color: black;
            font-family: 'Gotham Book';
            font-weight: 400;
        }
    }
}

.nav-perfil{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        a{
            width: 100%;
            display: inline-block;
            /* font-size: 1rem; */
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: 'Gotham Book';
            &:hover, &.active{
                background-color: #F5F4F2;
                font-weight: bold;
                font-family: 'Gotham';
            }
        }
    }
}

.nav-perfil-sup{
    list-style: none;
    li{
        a{
            text-decoration: none;
            color: black;
            font-family: 'Gotham Book';
            padding: 0.5rem;
            font-size: 0.9rem;
            &:hover, &.active{
                font-weight: bold;
                font-family: 'Gotham';
            }
        }
    }
}

/* .col-content-perfil{
    
} */

.ficha-arjeta, .ficha-direccion{
    form{
        width: 100%;
    }
    .datos{
        width: 90%;
    }
    .opciones{
        width: 10%;
    }
}

@media only screen and (min-width: 960px) and ( orientation: landscape ){
    .nav-perfil{
        width: 80%;
        li{
            a{
                padding: 1vw 0 1vw 10vw;
            }
        }
    }
    .ficha-arjeta, .ficha-direccion{
        form{
            width: 100%;
        }
    }
    .ficha-arjeta{
        width: 60%;
    }
    .ficha-direccion{
        width: 60%;
    }
}

@media (min-width: 1200px) {

    .ficha-arjeta, .ficha-direccion{
        width: 100%;
        form{
            width: 100%;
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    .head-perfil{
        h1{
            font-size: 1vw;
            font-family: 'Gotham Book';
            font-weight: 400;
        }
    }
    .nav-perfil{
        li{
            a{
                font-size: 0.9vw;
                .icon{
                    font-size: 1.1vw;
                }
            }
        }
    }
    .nav-perfil-sup{
        li{
            a{
                font-size: 0.9vw;
                padding: 1vw 2vw;
            }
        }
    }
    .col-content-perfil{
        h2{
            font-size: 0.9vw !important;
            font-family: 'Gotham Book';
            margin-bottom: 1vw;
        }
        a{
            text-decoration: none;
            color: black;
        }
        form{
            label{
                font-size: 0.9vw;
                &.newsletterStatus {
                    font-size: 0.8vw;
                    input {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
            a{
                font-size: 0.9vw;
                &.btn-delete-account {
                    font-size: 0.8vw;
                }
            }
        }
        
        .btn-g{
            font-size: 0.9vw;
        }
        
    }

    .ficha-arjeta, .ficha-direccion{
        h4{
            font-size: 1vw !important;
        }
        .numero-oculto{
            span{
                font-size: 0.8vw;
            }
            .icon{
                font-size: 1vw;
            }
        }
        .opciones{
            button{
                font-size: 1.5vw !important;
            }
        }
    }
}


@media (min-width: 1920px) {
    .nav-perfil{
        li{
            a{
                font-size: 1rem;
            }
        }
    }
}