@media (max-width: 576px) {
  
}

@media (min-width: 768px) {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    
}

@media only screen and (min-width: 960px) and ( orientation: landscape ){
    .col-nav{
        width: 39%;
    }
    .col-content-perfil{
        width: 60%;
    }
}

@media only screen and (min-width: 1200px){
    
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
 
}