@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.buscador{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    @include toAnim(0.3s);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-2rem);
    scale: .96;
    &.show{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        scale: 1;
    }
    .cols{
        padding: 1rem;
        &:nth-child(1){
            width: 50%;
        }
        &:nth-child(2){
            width: 50%;
            position: relative;
        }
    }
    .logo{
        width: 60%;
    }
    form{
        width: 50%;
        display: flex;
        align-items: center;
        padding: 0.5rem;
        overflow: hidden;
        background-color: rgba(#C4C4C4, .2);
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 5px;
        height: 25px;
        button{
            border: 0;
            background-color: transparent;
            font-size: 1rem;
            display: flex;
        }
        input{
            width: 100%;
            border: 0;
            background-color: transparent;
            font-size: 1rem;
            height: 25px;
            &::-webkit-input-placeholder { /* Edge */
                color: #C4C4C4;
                font-size: 0.8rem;
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #C4C4C4;
                font-size: 0.8rem;
            }
            
            &::placeholder {
                color: #C4C4C4;
                font-size: 0.8rem;
            }
        }
    }
    .close{
        position: absolute;
        top: 25px;
        right: 1rem;
        color: black;
        font-size: 1rem;
    }
    h3{
        font-family: 'Gotham';
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            a{
                text-transform: uppercase;
                color: black;
                text-decoration: none;
                &:hover{
                    color: #C4C4C4;
                }
            }    
        }
    }
    
}

@media (max-width: 576px) {
    header{
        z-index: 10;
        // min-height: 100px;
    }
    .buscador{
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        h3{
            font-size: 0.9rem !important;
        }
        ul{
            li{
                a{
                    font-size: 0.8rem;
                }
            }
        }
        .cols{
            /* display: flex;
            align-items: center; */
            &:nth-child(1), &:nth-child(2){
                width: 100%;
            }
            &:nth-child(2){
                /* position: inherit; */
            }
        }
        .close{
            top: 7%;
            font-size: 1rem;
        }

        form {
            width: 80%;
        }
    }
    .container-load-search{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    .buscador{
        .cols{
            padding: 2vw;
            &:nth-child(1){
                width: 25%;
            }
            &:nth-child(2){
                width: 80%;
            }
        }
        form{
            button{
                font-size: 1.1vw;
            }
            input{
                font-size: .75vw;
                &::-webkit-input-placeholder { /* Edge */
                    color: #C4C4C4;
                    font-size: 0.8vw;
                }
                
                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #C4C4C4;
                    font-size: 0.8vw;
                }
                
                &::placeholder {
                    color: #C4C4C4;
                    font-size: 0.8vw;
                }
            }
        }
        .close{
            font-size: 1.2vw;
        }
        ul{
            li{
                a{
                    font-size: 0.9vw;
                }
            }
        }
    }
}

@media (min-width: 1920px) {

}