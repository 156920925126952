.drop-menu{
    width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-2rem);
    scale: .97;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &.show{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        scale: 1;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            a{
                display: inline-block;
                text-decoration: none;
                color: black;
                text-transform: capitalize;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                font-family: 'Gotham Book';
                position: relative;
                font-size: .8vw;
                &::before{
                    content: '';
                    height: 1px;
                    width: 0%;
                    background-color: black;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    position: absolute;
                    top: 100%;
                    left: 0;
                }
                &:hover{
                    padding-left: 1%;
                    &::before{
                        width: 100%;
                    }
                }
            }
        }
    }
    .btn-g{
        font-size: 0.8vw;
        position: absolute;
        bottom: 1rem;
        left: 48%;
        opacity: 0;
        transform: translateX(-50%);
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }
    &:hover{
        .btn-g{
            left: 50%;
            opacity: 1;
        }
    }
}
                            