@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.carrito-rapido{
    position: fixed;
    top: 1rem;
    right: 1rem;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-2rem);
    scale: .96;
    z-index: 10;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    @include toAnim(0.3s);
    &.show{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        scale: 1;
        box-shadow: 0 0 25pt #00000047;
    }
    .close{
        color: black;
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        font-size: 1rem;
    }
    .content-carrito{
        overflow-y: scroll;
        padding-right: 5px;
        /* width */
        &::-webkit-scrollbar {
            width: 5px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888; 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }
    }
    h3{
        font-family: 'Gotham Book';
    }
}

.checkout, .carrito-rapido{
    .item-carrito{
        border-bottom: 1px solid #dee2e6;
        .img{
            width: 30%;
        }
        .desc{
            width: 70%;
            .op{
                &:nth-child(1){
                    width: 80%;
                }
                &:nth-child(2){
                    width: 20%;
                }
            }
        }
        h2{
            font-size: 1rem !important;
            font-family: 'Gotham Book';
        }
        p{
            font-size: 1rem;
        }
        .borrar{
            font-size: 1.5rem;
            color: #8d8d8d;
        }
    }
}

@media (max-width: 576px) {
  .carrito-height-iphone {
    .content-carrito{
        height: 55vh !important;
    }
  }
  .carrito-rapido{
    width: 100%;
    height: 100vh;
    right: 0;
    top: 0;
    .content-carrito{
        height: 60vh;

        &.height-iphone {
            height: 55vh;
        }
    }
  }
  .checkout, .carrito-rapido{
    h3{
        font-size: 0.8rem !important;
    }
    .item-carrito{
        h2{
            font-size: 0.8rem !important;
        }
        p{
            font-size: 0.7rem !important;
        }
    }
  }
}

@media (min-width: 768px) {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    .carrito-rapido{

        .content-carrito{
            height: 45vh; 
        }
    }

    .checkout{
        .ficha-arjeta{
            width: 100%;
        }
    }
}

@media only screen and (min-width: 1200px){
    .checkout{
        &.container{
            max-width: 90%;
        }
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
 
    .carrito-rapido{
        width: 26%;
        .close{
            top: 0.5rem;
            right: 1rem;
            font-size: 1rem;
        }
        .content-carrito{
            height: 45vh; 
        }
        .btn-g{
            font-size: 0.8vw;
        }
    }

    .checkout, .carrito-rapido{
        .item-carrito{
            h2{
                font-size: 0.8vw !important;
            }
            p{
                font-size: 0.7vw;
            }
            .borrar{
                font-size: 1.5vw;
            }
        }
    }

    .checkout{
        .item-carrito{
            .img{
                width: 23%;
            }
            .desc{
                width: 77%;
                align-items: center;
            }
            h2{
                font-size: 0.9vw !important;
            }
            p{
                font-size: 0.8vw;
            }
        }
        table{
            tr{
                td{
                    font-size: 0.9vw;
                    h4{
                        font-size: 1.1vw !important;
                        font-family: 'Gotham';
                    }
                }
            }
        }
    }

}
.transition {
    transition: all 0.5s;
}
.item-carrito-min {
    .img {
        width: 50pt !important;
    }
}
.carrito-rapido {
    .content-carrito {
        &.min {
            height: 21vh !important;
        }
    }
}