@media (min-width: 992px) {
    .icono-metodo{
        img{
          height: 2.3vw;
          &.visa{
            height: 1.6vw;
          }
        }
      }

    .lightwidget-widget{
        height: 16rem;
        margin-bottom: -10px;
    }

    .btn-g{
      font-size: 1rem;
    }
}

@media (max-width: 992px) {
    
}