
.cookies{

}


@media (max-width: 576px) {
    
}

@media (min-width: 768px) {
    .cookies{
        .modal-dialog{
            max-width: 768px;
            display: flex;
            align-items: center;
            min-height: calc(100% - var(--bs-modal-margin) * 2);
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    
}


