@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.compra{
    .content-compra{
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        h2, h3, p{
            color: black;
        }
        h2{
            font-size: 1.4rem;
        }
    }
}

@media (max-width: 576px) {

}

@media (min-width: 768px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    .compra{
        .content-compra{
            h2{
                font-size: 5vw !important;
            }
            h3{
                font-size: 2vw !important;
            }
            p{
                font-size: 1vw;
                margin: 0;
            }
        }
    }
}

@media (min-width: 1920px) {

}