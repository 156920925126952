@media (max-width: 576px) {
    .main{
        padding-top: 7rem;
        overflow-x: hidden;
    }

    h1{
        font-size: 1rem !important;
    }

    h2{
        /* font-size: 1.2rem !important; */
        font-size: .8rem !important;
    }

    h3{
        font-size: 1rem !important;
    }

    h4{
        font-size: 0.9rem !important;
    }

    p{
        font-size: 0.8rem;
        &.big-p{
            font-size: 1rem;
        }
    }

    .btn-g{
        font-size: 0.7rem;
    }

    .banner{
        .btn-big{
            font-size: 0.7rem;
            left: 12px;
            bottom: 50px;
            transform: translateX(0%);
        }
        .c-special{
            top: 45%;
        }
    }

    .special-item {
        &::before {
            background-color: rgba(0,0,0, 0);
        }

        .txt {
            top: 97%;
            left: 20%;
            h2{
                font-size: 1rem !important;
                color: white !important;
            }
        }
    }

    .img-blur {
        cursor: pointer;

        &:hover {
            img {
                filter: none;
                -webkit-filter: none;
            }
        }
    }

    .note-publi {
        a{
            font-size: 0.9rem;
        }
    }
    
    .link-header{
        p, a{
            font-size: 0.8rem;
        }
    }

    .icono-metodo{
        img{
          height: 1.8rem;
          &.visa{
            height: 1.4rem;
          }
        }
    }

    .lightwidget-widget{
        height: 24rem;
        margin-bottom: -8px;
    }

    form{
        input[type='text'], input[type='email'], input[type='number'], input[type='tel'], input[type='password'], input[type='date'], input[type='time'], select, label, textarea{
            font-size: 0.75rem;
        }
        input, select {
            border: 1px solid #dee2e6;
        }
        .talla{
            position: relative;
            display: inline-block;
            
            input[type=radio].css-checkbox {
                height:1px; 
                width:1px; 
            }
            
            input[type=radio].css-checkbox + label.css-label {
                width: 1.5rem;
                height:1.5rem;
                font-size: 0.8rem;
            }
        }

        .color-radio{
            width: 1.5rem;
            height: 1.5rem;
        }

        .link-reset-password {
            font-size: .65rem;
            a {
                font-size: .65rem !important;
            }
        }
        .container-check-s {
            padding-left: 25px;
            small{
            font-size: .65rem;
            }
        }

        .return-login {
            font-size: .65rem !important;
        }
    }
    
    .feedback{
        background-color: #F5F4F2;
        .fondo{
            display: none;
        }
        .content-banner{
            width: 100%;
            position: relative;
            top: inherit;
            left: inherit;
            transform: translate(0%, 0%);
            padding: 2rem;
        }
        .slide-process{
            display: none;
        }
    }

    .pregunstas, .contactanos{
        .content-banner{
            width: 100%;
            position: relative;
            top: inherit;
            left: inherit;
            transform: translate(0%, 0%);
            padding: 1rem;
            h2{
                color: black;
            }
            h3{
                color: black;
            }
        }
    }

    .filtros-activos{
        .chip{
            font-size: 0.8rem;
            padding: 0.2rem 0.3rem;
            line-height: 100%;
        }
        a{
            font-size: 0.8rem;
            padding: 0.3rem;
        }
    }
    

    .item-nueva-coleccion{
        background-color: black;
        padding: .55rem .25rem;
        margin: 0;
        h3{
            color: white;
        }
        .btns-item-eva-coleccion {
            top: 92%;
            left: 5%;
            padding-top: 0.5rem;
            h3 {
                font-weight: 500;
                font-family: "Gotham Book";
                color: white;
            }
            .btn-g{
                &.bb{
                    position: absolute;
                    min-width: 145px;
                    left: 0;
                    margin-top: 0.5rem;
                }
            }
        }
        .btn-g{
            border: 1px solid #dee2e6;
            &.bb{
                background-color: white;
                color: black !important;
            }
        }
    }

    .user{
        h2{
            font-size: .8rem !important;
        }
        .simple-link, a{
            font-size: 0.7rem;
        }

        label {
                font-size: .75rem !important;
        }
        .btn-g {
            font-size: .7rem;
        }
        .link-reset-password {
            font-size: .7rem !important;
        }
    }

    .btn-g, a.btn-g, button.btn-g{
        border: 1px solid #dee2e6;
        font-size: .7rem;
    }

    .product-page {
        .product-description {
            font-size: .75rem !important;
            label, h3{ 
                font-size: .75rem !important;
            }
            h3.nombre-producto {
                font-size: 1.2rem !important;
            }
        }
    }

    .opcion-mobile {
        a span {
            font-size: 0.7rem;
        }
    }

    .product-filter {
        width: 60%;
        padding: 1rem;
        .item-filtro-link, .item-filtro .dropdown-toggle {
            padding: 0.25rem 1rem;
            font-size: .65rem;
        }
    }
    
    .modal-new-card {
        .modal-dialog {
            width: 90%;
            margin: 0px auto;

            .modal-title {
                font-size: .8rem !important;
            }

            form {
                label {
                    font-size: .65rem;
                }
            }
        }
    }

    .recomendados-titulo {
        font-size: 1.25rem !important;
    }
    
    #table {
        height: auto;
    }

    .ps-0125{
        padding: 0 !important;
    }

    .titulo-banner-collecion {
        top: 8%;
        left: 0%;
        width: 100%;
        h2 {
            font-size: .75rem !important;
            font-style: normal;
            text-align: center;
            p {
                margin: 0;  
            }
            p:last-child {
                margin-top: .5rem;
                span {
                }
            }
        }
    }

    .content-user {
        .user {
            // padding-top: 2rem !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1.5rem;
            padding: 4rem 1rem !important;

            &.register {
                gap: 0rem;
            }
        }
    }

    .input-coupon {
        font-size: 0.7rem !important;
    }

    #header {
        .panel-header {
            min-height: 110px;
        }
    }

    .bolsa-checkout {
        font-size: .75rem;
        h4 {
            font-size: .75rem !important;
        }
    }
    .ficha-pago-exitoso {
        width: 100%;
        margin: 0 auto;
    }

    .favicon-banner {
        width: 35px;
        position: absolute;
        bottom: 20px;
        left: 14%;
    }

    #FindmySizeModal {
        .close-modal {
            top: 5px;
        }
    }

    .checkout {
        .btn-new-address {
            font-size: .75rem !important;
        }
    }

    .btn-delete-account {
        font-size: 0.8rem;
        color: #000;
    }


    .modal-imagen-min {
        .modal-dialog {
            .modal-content {
                img {
                    max-height: 100vh;
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                }
                .close-modal span{
                    color: white;
                    font-size: .65rem;
                }
                .review-swiper-button-prev, .review-swiper-button-next {
                    top: 50%;
                    font-size: 1rem;
                    outline: none;
                }
            }
        }
    }

    .content-checkout-invitado {
        background: #F5F4F2;
    }

    .ficha-direccion {
        form {
            .datos {
                padding: .5rem;
                h4, p, .btn-g{
                    font-size: .7rem !important;
                }
            }
        }

        .opciones {
            width: 15%;
        }
    }

    #btnActiveModal {
        font-size: .7rem;
    }

    .banner-ready-to-wear {
        .favicon-gris-banner {
            width: 20px;
            margin-top: .5rem;
        }
        .btn-big{
            font-size: 0.7rem;
            left: 19%;
            margin-top: 1rem;
        }
    }

    .estrategia-page {
        position: relative;
        // height: 700px;


        .contenido-estrategia-page {
            width: 100%;
            text-align: justify;
            padding: 0 1rem !important;
        }

        .img-estrategia{
            position: relative;
            width: 360px;
            height: 220px;
            top: 0%;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 2rem !important;
        }
    }   
    .compra-page {
        height: auto;
        .content-compra {
            padding-top:5rem;
        }
    }

    .NavPoliticas {
         li a {
            font-size: .7rem;
         }
    }

    .col-13 {
        width: 115% !important;
    }

    .content-cookies {
        width: 100%;
        height: auto;
        position: fixed;
        top: 100%;
        z-index: 998;
        background-color: white;
        transform: translateY(-200px);
        padding: .5rem 0;

        &.cookies-height-iphone {
            transform: translateY(-185px);
            transition: .5s ease-in;   
        }
        .btn-g {
            font-size: .6rem !important;
        }
        h2 {
            font-size: .7rem !important;
            margin-bottom: 0;
        }
        p {
            font-size: .65rem;
        }
    }    
}