// only desktop to hd

@media only screen and (min-width: 1200px) and (max-width: 1420px) {
  .main {
    padding-top: 4vw;
  }
}

@media only screen and (min-width: 1420px) and (max-width: 1600px) {
  .main {
    padding-top: 4vw;
    &.no-padding {
      padding: 0;
    }
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {
  .main {
    padding-top: 4vw;
    &.no-padding {
      padding: 0;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px) {
  h2 {
    font-size: 1vw !important;
  }

  h3 {
    font-size: 0.9vw !important;
  }

  h4 {
    font-size: 0.8vw !important;
  }

  p {
    font-size: 0.9vw;
    &.big-p {
      font-size: 1vw;
    }
  }

  .btn-g {
    font-size: 0.8vw !important;
  }

  form {
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="tel"],
    input[type="password"],
    input[type="date"],
    input[type="time"],
    select,
    label,
    textarea {
      font-size: 0.8vw;
    }
  }

  form {
    .color-radio {
      width: 1.7vw;
      height: 1.7vw;
    }
    .container-check-s {
      /* height: 1.2vw; */
      small {
        font-size: 0.7vw;
      }
    }

    .link-reset-password {
      font-size: 0.7vw;
    }
  }

  form .talla input[type="radio"].css-checkbox + label.css-label {
    font-size: 1vw;
  }

  .note-publi {
    a {
      font-size: 1vw;
      text-transform: uppercase;
      text-decoration: underline !important;
    }
  }

  .banner {
    .btn-big {
      bottom: 27%;
      font-size: 0.9vw;
      text-transform: uppercase;
    }
    .titulo-big {
      font-size: 2.7vw !important;
    }
  }

  .item-nueva-coleccion {
    &:first-child {
      padding-right: 0.125rem;
    }
    &:last-child {
      padding-left: 0.125rem;
    }
    h3 {
      font-size: 1vw !important;
    }
    .btn-g {
      font-size: 1vw;
    }
  }

  .combinacionacion-grid {
    h4 {
      font-size: 1vw !important;
    }
  }

  .breadcrumbs {
    width: 83%;
    margin: 0 auto;
    a {
      color: black;
      text-decoration: none;
      padding-right: 1vw;
      font-size: 1vw;
    }
    .oval {
      font-size: 0.4vw;
      margin-right: 1vw;
    }
  }

  .moda-consciente {
    &.container {
      max-width: 85%;
    }
    h3 {
      /* font-size: 1.5vw !important; */
      /* font-size: 1vw !important; */
    }

    p {
      /* font-size: 1.3vw; */
      /* font-size: 1vw !important; */
    }
  }

  .head-feed-intagram {
    h3 {
      font-size: 1vw !important;
    }
    a {
      font-size: 0.9vw;
    }
  }

  .lightwidget-widget {
    height: 25vw;
  }
  .feed-instagram {
    margin-bottom: -10px;
  }

  .mapUbicacion {
    width: 100%;
    height: 100vh;
    & > div {
      width: 100%;
      height: 100%;
    }
  }

  .item-ayuda-contacto {
    .content {
      min-height: 6vw;
    }
  }

  .user {
    form {
      h2 {
        /* font-size: 1.5vw !important; */
        font-size: 1vw !important;
      }
    }
  }

  .modal-estado-envio {
    .modal-content {
      width: 100%;
      margin: 0 auto;
      border-radius: 0;
      .modal-body {
        .title-modal {
          font-size: 1vw !important;
          font-weight: 600;
        }
        p {
          font-size: 0.9vw;
        }
      }
    }
  }

  .filtros-activos {
    .chip {
      font-size: 0.8vw;
      padding: 0.2vw 0.3vw;
      line-height: 100%;
    }
    a {
      font-size: 0.8vw;
      padding: 0.3vw;
    }
  }

  .error-msg {
    font-size: 0.8vw;
  }

  .collage {
    position: relative;
    .item {
      width: 100%;
    }
  }

  .recomendados-titulo {
    font-size: 1.25vw !important;
  }

  .estrategia-page {
    position: relative;
    height: 750px;
    h2 {
      font-size: 1.25vw !important;
    }

    .contenido-estrategia-page {
      width: 65%;
      padding-top: 2.5rem;
      text-align: justify;
    }

    .img-estrategia {
      position: absolute;
      width: 57vw;
      height: 67vh;
      top: 15%;
      left: 33%;
    }
  }

  .titulo-banner-collecion {
    top: 24%;
    left: 10%;
    h2 {
      font-size: 1.75vw !important;
      font-style: normal;
      p {
        margin: 0;
        margin-bottom: 0.5rem;
      }
      p:last-child {
        margin-top: 0.9rem;
      }
    }
  }

  .manifiesto-content {
    padding-top: 4vw;
    p span {
      font-size: 32px !important;
    }
  }

  .banner-ready-to-wear {
    .favicon-gris-banner {
      width: 20px;
      margin-top: 0.5rem;
    }
    .btn-big {
      font-size: 0.9vw;
      text-transform: uppercase;
      left: 6.5%;
      margin-top: 1rem;
    }
  }

  .col-lg-35 {
    width: 40% !important;
  }

  .NavPoliticas {
    li {
      a {
        font-size: 0.85vw;
      }
    }
  }

  .head-perfil {
    padding-top: 4vw !important;
  }

  .combinaciones-page {
    padding-top: 4vw;
  }

  .content-favoritos {
    height: 330px;
  }
  .step-content {
    padding-top: 4vw !important;
    padding-bottom: 2vw !important;
  }

  .content-checkout-invitado {
    padding: 5vw 0 !important;

    .contenido-box {
      height: 80px;
    }
  }

  .content-cookies {
    p {
      font-size: 0.8vw;
      width: 90%;
      margin: 0 auto;
    }
    .btn-g {
      font-size: 0.7vw !important;
    }
    h2 {
      font-size: 0.9vw !important;
      margin-bottom: 0;
    }
  }

  .gif-card-page {
    padding-top: 2rem;
  }

  .placeholder-gris {
    &::placeholder { 
      font-size: .6vw; 
    }
}
}
