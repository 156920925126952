.react-select-container {
    border: 1px solid cccccc;
}

.react-select__single-value {
    font-size: 0.8rem !important;
    color: black !important;
}

/* .react-select__control{
    min-height: 20px !important;
} */

/* .react-select__value-container{
    
} */

.react-select__control {
    border-radius: 0 !important;
    border: 1px solid #dee2e6 !important;
}

.react-select__indicator {
    svg {
        path {
            fill: #cccccc;
        }
    }
}

/* .wp-select{
    .react-select-container{
        transform: scale(0.8);
        .react-select__control{
            height: 15px;
        }
        .react-select__input-container{
            height: 15px;
        }
    }

} */

.react-select__input-container {
    /* height: 15px; */
    /* padding: 0 !important;
    margin: 0 !important; */
}

.react-select__menu {
    font-size: 0.7rem;
    max-height: 7rem;
    line-height: 0.5rem;
    // overflow: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1920px) {
    .react-select__single-value {
        font-size: 0.8vw !important;
    }
    .react-select__menu {
        font-size: 0.8vw;
        max-height: 4rem;
        line-height: 0.5rem;
    }
}
