@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}


@media (max-width: 576px) {

    .banner{
        
        &.tarjeta-regalo{
            /* .fondo{
                display: none;
            } */
            .content-banner{
                width: 100%;
                position: absolute;
                top: 60%;
                left: 0;
                z-index: 0;
                transform: translate(0%, 0%);
                background-size: cover;
                background-position: center left;
                background-color: transparent;
                padding: 1rem;

                &.saldo {
                    top: 30%;
                    .content-saldo {
                        background-color: rgba(141, 141, 141, .75);
                    }
                }
            }
            h2{
                font-size: 1rem !important;
                margin-top: 2rem;
                
            }
            .btn-g {
                font-size: .65rem;
            }
        }
    }

}

@media (min-width: 768px) {
   .banner{
        .content-banner{
            background-image: none !important;
        }
   }
}

@media (min-width: 992px) {
    
}

@media (max-width: 992px) {
   
}

@media only screen and (min-width: 768px) and (max-width: 1200px)  {

   
}

@media only screen and (min-width: 992px) and (max-width: 1200px) and (orientation: landscape)  {
   
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {


}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    
}