@media (min-width: 1920px) {

    h2{
        font-size: 1.8rem;
    }

    h3{
        font-size: 1.1rem;
    }

    h4{
        font-size: 1.1rem;
    }

    p{
        font-size: 1rem;
        .big-p{
            font-size: 1.5rem;
        }
    }
    
    header{
        .logo{
            height: 2rem;
        }
    }

    .product-description{
        p{
            font-size: 0.9rem;
        }
    }

}