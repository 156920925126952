@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.nft{
    .btn-g{
        &:nth-child(1){
            margin-right: 3rem;
        }
    }
}

.modalNft{
    .modal-dialog{
        max-width: 80%;
        .modal-content{
            .close{
                position: absolute;
                top: 1rem;
                right: 1rem;
                background-color: transparent;
                border: 0;
                color: white;
                font-size: 1rem;
            }
        }
    }
}

@media (max-width: 576px) {
    .nft {
        .btn-g {
            font-size: .7rem;
        }
    }
}

@media (min-width: 768px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
 
    .nft{
        .btn-g{
            &:nth-child(1){
                margin-right: 12vw;
            }
        }
    }

    .modalNft{
        .modal-dialog{
            .modal-content{
                .close{
                    font-size: 1.4vw;
                }
            }
        }
    }

}

@media (min-width: 1920px) {

}