@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

/* header{
    display: none;
} */


.soon{
    width: 90%;
}
.logo-soon{
    width: 50%;
}

@media (max-width: 576px) {
  
}

@media (min-width: 768px) {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    
}

@media only screen and (min-width: 1200px){
    .soon{
        width: 60%;
    }
    .logo-soon{
        width: 18%;
    }

    .instagram{
        a{
            img{
                height: 2vw;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
 
  

}