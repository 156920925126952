@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

header{
    .menu{
        list-style: none;
    }
    .menu{
        li{
            position: relative;
            a{
                text-transform: uppercase;
                text-decoration: none;
                font-family: 'Gotham Book';
                color: #000000;
                @include toAnim(0.3s);
                &:hover, &.active{
                    font-weight: 700;
                    font-family: 'Gotham';
                }
            }
            .drop-menu{
                position: absolute;
                top: 100%;
            }
        }
    }
    .idiomas{
        p{
            color: black;
            span{
                color: black;
                text-decoration: none;
                cursor: pointer;
                &.active{
                    font-family: 'Gotham';
                    font-weight: bold;
                }
            }
        }
    }
    .options{
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                a, button{
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 0;
                    background-color: transparent;
                    @include toAnim(0.3s);
                    &:hover{
                        color: #525252;
                    }
                }
                .opt-user{
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background-color: white;
                    text-align: left;
                    padding: 1rem 0;
                    visibility: hidden;
                    opacity: 0;
                    width: 100vw;
                    z-index: 9;
                    @include toAnim(0.5s);
                    &.show {
                        opacity: 1;
                        visibility: visible
                    }
                    .title{
                        font-size: 1rem;
                        font-weight: bold;
                    }
                    li{
                        text-align: left;
                        /* @include toAnim(0.3s); */
                        a{
                            color: black;
                            text-decoration: none;
                            justify-content: flex-start;
                            @include toAnim(0.3s);
                            &:hover, &.active{
                                text-decoration: none;
                                font-weight: bold;

                            }
                        }
                    }
                    &.log{
                        display: none;
                    }
                }
                // &.logged:hover{
                //     .opt-user{
                //         opacity: 1;
                //         visibility: visible;
                //     }
                // }
            }  
        }
    }
}

@media (max-width: 576px) {
    //al tener el header fixed se debe ajustar el padding-top de .main
    header{
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1001;
        background-color: white;
        .logo{
            height: 2rem;
        }
        .options{
            position: relative;
            ul{
                .wp-call-menu{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(25%);
                    a{
                        padding: 0 !important;
                    }
                }
                li{
                    position: relative;
                    .opt-user {
                        transform: translateX(-41.5%);
                        width: 103vw;
                        padding-inline-start: 1rem;
                        .title {
                            font-size: .8rem;
                        }
                        li {
                            a{
                                font-size: 0.8rem;
                                white-space: nowrap;
                                margin-bottom: .25rem;
                                &:first-child {
                                    margin-top: .25rem;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            a{
                font-size: 1.3rem;
            }
            .call-menu{
                font-size: 1.6rem;
            }
        }
        .menu{
            z-index: 10;
            -webkit-box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.24); 
            box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.24);
            li{
                a{
                    width: 100%;
                    font-size: 0.9rem;
                    display: inline-block;
                    text-align: center;
                }
            }
        }
        .idiomas{
            text-align: right;
        }
        .menu-mobile{
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: white;
            z-index: 1001;
            transform: translateX(100%);
            opacity: 0;
            visibility: hidden;
            @include toAnim(0.3s);

            .content-opt{
                position: relative;
                height: 90%;
            }
            .back{
                color: black;
                font-size: 1.2rem;
                &.opt{
                    position: relative;
                    left: inherit;
                    top: inherit;
                }
            }
            .opt, .opt-p{
                @include toAnim(0.3s);
                /* display: none; */
                visibility: hidden;
                opacity: 0;
                transform: translateX(2rem);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                &.show{
                    /* display: block; */
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(0rem);
                }
            }
            button{
                border: 0;
                background-color: transparent;
            }
            .scroll-content{
                height: 100%;
                overflow-y: scroll;
            }
            ul{
                list-style: none;
                font-size: 0.9rem;
                
                li{
                    a{
                        text-decoration: none;
                        text-transform: uppercase;
                        padding: 0.3rem 0;
                        display: inline-block;
                        color: black;
                        font-size: 0.9rem;
                    }
                    button{
                        font-size: 1.2rem;
                        padding: 0.3rem 0;
                    }
                }
            }
            .option{
                // border-bottom: 1px solid #A5AAAE;
                .line-option {
                    height: .5px;
                    background-color: #A5AAAE;
                    width: 100vw;
                    left: -1.5rem;
                    bottom: 0;
                }
                .close{
                    color: black;
                    font-size: 1.2rem;
                }
            }
            
            &.show{
                transform: translateX(0rem);
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

@media (min-width: 768px) {
    header{
        position: relative;
        .menu{
            &.desk{
                display: block;
            }
            &.mobile{
                display: none !important;
            }
        }
        .options{
            .call-menu{
                display: none !important;
            }
        }
    }
}

@media (min-width: 992px) {
    header{
        position: relative;
        .menu{
            &.desk{
                display: block;
            }
            &.mobile{
                display: none !important;
            }
        }
        .options{
            .call-menu{
                display: none !important;
            }
        }
    }
}

@media (max-width: 992px) {
    header{
        /* position: relative; */
        .menu{
            &.desk{
                display: none;
            }
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0%;
            background-color: white;
        }
        .menu-mobile{
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: white;
            z-index: 1001;
            transform: translateX(100%);
            opacity: 0;
            visibility: hidden;
            @include toAnim(0.3s);

            .content-opt{
                position: relative;
                height: 90%;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px)  {

    header{
        .logo{
            height: 2rem;
        }
        .menu{
            li{
                a{
                    font-size: 0.9rem;
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) and (orientation: landscape)  {
    header{
        .menu{
            li{
                a{
                    font-size: 0.8rem;
                }
            }
        }
    }

}

@media only screen and (min-width: 768px) and (max-width: 1200px){

    // header{
    //     padding-bottom: 2rem;
    //     // height: 12rem;
    //     .menu{
    //         top: inherit;
    //         bottom: 0;
    //     }
    //     .options{
    //         width: 60%;
    //     }
    // }

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    header{
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: white;
        .logo{
            height: 2.6vw;
        }
        
        .menu{
            li{
                a{
                   font-size: 1vw;
                }
            }
        }
        .idiomas{
            p{
                font-size: 0.8vw;
                span{
                    &.active{
                        font-family: 'Gotham';
                        font-weight: bold;
                    }
                }
            }
        }
    }
    header{
        .options{
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                li{
                    position: relative;
                    a{
                        font-size: 1.4vw;
                    }
                    .opt-user{
                        
                        padding: 1vw;
                        transform: translate(-86.7vw, 1vw);
                        padding-left: 4.5vw;
                        .title{
                            font-size: 0.8vw;
                            padding: 0.3vw 0.5vw;
                            margin-bottom: 0.3vw;
                        }
                        li{
                            a{
                                font-size: 0.8vw;
                                white-space: nowrap;
                                padding: 0.3vw 0.5vw;
                            }
                        }
                        &.log{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

//Add by OSCAR
.name-user {
    font-size: 10pt;
    margin-left: 4pt;
    color: #919191;
    &:hover {
        color: #2e2e2e;
    }
}