@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.newsletter{
    background-color: #B9D3D7;
    .content-form{
        width: 88%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .col{
            padding: 2rem 0;
            &:nth-child(1){
                width: 45%;
            }
            &:nth-child(2){
                width: 55%;
            }
        }
        .form-news{
            width: 70%;
            margin: 0 auto;
            form{
                input{
                    padding: 0.5vw;
                    margin-bottom: 0.5vw;
                }
                label{
                    margin-bottom: 0.5vw;
                    font-size: 0.8vw;
                }
                .btn-g{
                    font-size: 0.9vw;
                }
                a{
                    font-size: 0.8vw;
                }
            }
        }
    }
    .back{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 0;
    }
}


@media (max-width: 576px) {

}

@media (min-width: 768px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    .newsletter{
        
        .content-form{
            width: 88%;
            .col{
                width: 50%;
                padding: 4vw 0;
                &:nth-child(1){
                    width: 45%;
                }
                &:nth-child(2){
                    width: 55%;
                }
            }
            .form-news{
                margin-left: 25%;
            }
        }
        .back{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            z-index: 0;
        }
    }
    
}

@media (min-width: 1920px) {

}